span.data-grid-container,
span.data-grid-container:focus {
  outline: none;
}

.data-grid-container .data-grid {
  table-layout: fixed;
  border-collapse: collapse;
}

.data-grid-container .data-grid .cell.updated {
  background-color: rgba(0, 145, 253, 0.16);
  transition: background-color 0ms ease;
}
.data-grid-container .data-grid .cell {
  /* height: 17px; */
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: cell;
  background-color: white;
  transition: background-color 500ms ease;
  vertical-align: middle;
  text-align: right;
  border: 1px solid rgb(44, 44, 46);
  padding: 10px;
  font-size: 24px;
}
.data-grid-container .data-grid .cell.selected {
  border: 1px double rgb(33, 133, 208);
  transition: none;
  box-shadow: inset 0 -100px 0 rgba(33, 133, 208, 0.15);
}

.data-grid-container .data-grid .cell.read-only {
  background: whitesmoke;
  color: #999;
  text-align: center;
}

.data-grid-container .data-grid .cell > .text {
  padding: 2px 5px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.data-grid-container .data-grid .cell > input {
  outline: none !important;
  border: 2px solid rgb(33, 133, 208);
  text-align: right;
  width: calc(100% - 6px);
  height: 11px;
  background: none;
  display: block;
}

.data-grid-container .data-grid .cell {
  vertical-align: bottom;
}

.data-grid-container .data-grid .cell,
.data-grid-container .data-grid.wrap .cell,
.data-grid-container .data-grid.wrap .cell.wrap,
.data-grid-container .data-grid .cell.wrap,
.data-grid-container .data-grid.nowrap .cell.wrap,
.data-grid-container .data-grid.clip .cell.wrap {
  white-space: normal;
}

.data-grid-container .data-grid.nowrap .cell,
.data-grid-container .data-grid.nowrap .cell.nowrap,
.data-grid-container .data-grid .cell.nowrap,
.data-grid-container .data-grid.wrap .cell.nowrap,
.data-grid-container .data-grid.clip .cell.nowrap {
  white-space: nowrap;
  overflow-x: visible;
}

.data-grid-container .data-grid.clip .cell,
.data-grid-container .data-grid.clip .cell.clip,
.data-grid-container .data-grid .cell.clip,
.data-grid-container .data-grid.wrap .cell.clip,
.data-grid-container .data-grid.nowrap .cell.clip {
  white-space: nowrap;
  overflow-x: hidden;
}

.data-grid-container .data-grid .cell .value-viewer,
.data-grid-container .data-grid .cell .data-editor {
  display: block;
}
.data-editor {
  padding: 25px;
  align-items: center;
}
.react-table-container {
  width: 95%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* REACT TAG INPUT */
/*  */
.td .react-tag-input__tag {
  /* background-color: #f08d31 !important; */
  background: -webkit-linear-gradient(130deg,#63ddd3,#31f080);
  font-size: 16px !important; /* specific font size */
  color: black;
  border-color: transparent !important;
  border: 1px transparent !important;
  border-radius: 5px !important;
  margin-block: 5px !important;
}
.td .react-tag-input__tag:hover {
  box-shadow: 0 0 7px #10ffcb;
}
.td .react-tag-input {
  font-size: 1.3rem !important; /* responsive font size */
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: flex-start;
  min-height: 60px;
  background: #fff !important;
  font-size: 16px !important; /* specific font size */
  color: #333 !important;
  border: 1px solid #e1e1e1 !important;
  box-shadow: 0 0 7px #ffffff;
  border: 0px transparent !important;
  border-radius: 10px !important;
  width: 250px !important;
}
.td .react-tag-input__tag__remove {
  background: transparent !important;
}
.td input.react-tag-input__input::placeholder {
  font-size: 12px !important; /* specific font size */
  color: #333 !important;
}
.td .react-tag-input__input {
  font-size: 16px !important; /* specific font size */
  background: transparent;
  color: rgb(0, 0, 0) !important;
}
.tooltip{
  font-size: 18px !important;
}