@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400&display=swap");
.App {
  background-color: #121212;
  min-height: 100vh;
}

body {
  background-color: #121212 !important;
}

.mcontainer2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 70vw;
  max-width: 1000px;
  margin: auto;
  margin-top: 8rem;
  margin-bottom: -80px;
  text-align: center;
}

.mcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 15%;
  text-align: center;
}
.mcontainer h1, .mcontainer2 h1 {
    font-weight: 600;
    font-size: 54px;
    /* line-height: 96px; */
    background: -webkit-linear-gradient(180deg,#fc5c7d,#6a82fb);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.mcontainer h2, .mcontainer2 h2{
  font-family: Poppins,"sans-serif";
    font-style: normal;
    font-weight: 600;
    font-size: 64px;
    line-height: 96px;
    background: -webkit-linear-gradient(180deg,#11998e,#38ef7d);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* margin-top: 5%; */
}
.label,
.required-label {
  color: #fff;
  margin-block: 1rem;
  font-size: 1.5rem;
  text-align: center;
}
.required-label::after {
  content: "*";
  color: red;
  margin-left: 2px;
}
.submit-music-fix {
  display: flex;
  align-items: center ;
  flex-direction: column;
}

.submit-music-fix2 {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.App-link {
  color: #61dafb;
}
.title,
.subtitle {
  color: white;
}
.s {
  margin-top: 20vh;
  height: 10vh; /* Magic here */
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sp {
  color: "white";
}
.rdio {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.rdio label {
  margin-top: 10px;
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
  width: 150px;
  padding: 10px;
  border-radius: 10px;
  border: solid 1px #31f080 !important;
  /* box-shadow: 0 0 10px #10efff !important; */
  transition: all 0.3s;
  color: #ffffff;
  text-align: center;
}

.rdio label:hover {
  box-shadow: 0 0 15px #2cf07e !important;
}

.rdio input[type="radio"] {
  display: none;
}

.rdio input[type="radio"]:checked + label {
  background-color: #31f080;
  border: solid 1px #31f080;
  color: #000;
}
.btn {
  margin-top: 10px;
  margin-bottom: 50px;
  background-color: #31f080 !important;
  color: black !important;
  border-color: black;
}
.btn1 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 50%;
}
/* navbar */
header {
  position: relative;
  top: 24px;
  max-width: 1255px;
  width: 95%;
  height: 72px;
  margin: auto;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: hidden;
  background: linear-gradient(
    126.6deg,
    rgba(255, 255, 255, 0.06) 28.69%,
    rgba(255, 255, 255, 0.084) 100%
  );
  backdrop-filter: blur(140px);
  border-radius: 39px;
}
.logo {
  cursor: pointer;
  width: 300px;
  margin-left: 20px;
}
.navlinks > ul {
  display: inline-flex;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
  margin-bottom: 0;
}
.navlinks > ul > li {
  list-style-type: none;
  margin-right: 20px;
}
.navlinks > ul > li > a {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  text-decoration: none;
}
.active {
  background: -webkit-linear-gradient(180deg, #11998e 0%, #38ef7d 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.navlinks > ul > li > a:hover {
  opacity: 0.8;
  transition: opacity 0.3s ease-in-out;
}
.toggle {
  display: none;
  width: 45px;
  height: 45px;
  background-size: 30px;
  background-position: center;
  cursor: pointer;
  border-radius: 90%;
  float: right;
}
.red {
  background: #fc5c7d;
  background: -webkit-linear-gradient(180deg, #fc5c7d 0%, #6a82fb 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.green {
  background: #11998e;
  background: -webkit-linear-gradient(180deg, #11998e 0%, #38ef7d 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.menu {
  position: fixed;
  top: 0;
  background: #000000d9;
  z-index: 100;
  right: 0;
  width: 590px;
  height: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: scale-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  backdrop-filter: blur(10px);
}
.menu ul {
  position: relative;
  list-style: none;
  margin-bottom: 200px;
}
.menu ul li {
  margin-top: 40px;
  transform: translateY(-50%);
}
.menu ul li .links {
  text-decoration: none;
  font-size: 30px;
  letter-spacing: 2.93px;
  color: #fff;
  opacity: 1;
  font-weight: normal;
  font-family: "Source Sans Pro", sans-serif;
}
.menu ul li .links:hover {
  color: #6dd400;
  transition: 0.7s all ease-in-out;
}
@keyframes scale-in-right {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    opacity: 1;
  }
}
/* media queries */
@media only screen and (min-device-width: 0px) and (max-device-width: 480px) {
  header {
    padding: 10px 40px !important;
  }
  .menu {
    width: 250px;
    display: flex;
  }
  .logo {
    max-width: 200px;
    width: auto;
    margin-left: 0px;
  }
  .navlinks {
    display: none;
  }
  .toggle {
    display: flex;
    width: 40px;
    height: 40px;
    margin-left: auto;
  }
}
@media only screen and (min-device-width: 481px) and (max-device-width: 768px) {
  header {
    padding: 10px 60px !important;
  }
  .menu {
    width: 300px;
    display: flex;
  }
  .toggle {
    display: flex;
    width: 40px;
    height: 40px;
  }
  .navlinks {
    display: none;
  }
}

/* Columns */
.cols {
  overflow-y: hidden;
  /* border: 1px solid #676767; */
  font-size: 12px;
}
.lab {
  color: #fff;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
}
.new {
  display: flex;
  gap: 40px;
}
.th-content {
  background: #121212;
  border-top: 1px solid #000;
}

/* INSTRUCTIONS */
.hometext h1 h2 {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
}
.instructions {
  display: flex;
  flex-direction: column;
  width: 70vw;
  max-width: 1000px;
  margin: auto;
}
.instructions h1 {
  font-weight: 600;
  font-size: 42px;
  background: -webkit-linear-gradient(180deg,#fc5c7d,#6a82fb);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Poppins", sans-serif;
  margin-block: 40px;
}

  /* font-size: 36px; */
  /* font-weight: bold; */
  /* color: #fff; */
  /* text-align: center; */
  /* margin: 30px; */
.instructions h2 {
  font-family: Poppins,"sans-serif";
    font-style: normal;
    font-weight: 600;
    /* font-size: 64px; */
    background: -webkit-linear-gradient(180deg,#11998e,#38ef7d);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.instructions h3 {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  color: #fff;
  margin: 10px;
  background: -webkit-linear-gradient(180deg,#11998e,#38ef7d);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.instructions .imp{
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  color: #fff;
  margin: 10px;
}
.instructions p {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: #ebebeb;
  text-align: left;
  margin: 10px;
}
.instructions .sample {
  text-decoration: underline;
}


/* PROFILE */
.profile {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.profile-page-container {
  margin-top: 5%;
  text-align: center;
}
.profile-page-container h1 {
  font-weight: 600;
  font-size: 64px;
  background: -webkit-linear-gradient(180deg,#fc5c7d,#6a82fb);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* SUBMISSION PAGE + BUTTONS */
.loading-bar-label {
  font-size: 1.5rem;
  color: aliceblue;
}
.submissions {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  gap: 80px;
}
.submissions h3 {
  font-size: 18px;
  color: #fff;
}
.submissions p {
  font-size: 18px;
  color: #fff;
}

.submitmusic {
  justify-content: center;
  display: flex;
  font-size: 18px;
  border-radius: 10px !important;

  /* background-color: #31f080 !important; */
  background: -webkit-linear-gradient(130deg,#63ddd3,#31f080);
  color: #000 !important;
  border-color: transparent !important;
  border: 1px transparent !important;
}
.submitmusic:hover {
  filter: drop-shadow(0px 2px 5px #00cf4f);
  /* border: 1px transparent !important; */
}
.submitmusic:disabled {
  background: transparent !important;
  border: 1px solid#31f080 !important;
  color: rgb(204, 204, 204) !important; 
}
.submitmusic:active {
  box-shadow: 0 0 10px #10efff !important;
  border: 0px transparent !important;
}


/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
/* PAGINATION COLLECTED INFO + PROFILE */
.pagen {
  color: white;
  border: solid 2px #31f080;
  padding: 0.5rem;
  display: flex;
  border-radius: 10px;
  /* max-height: 7vh; */
  justify-content: center;
  align-items: center;
  
}

.pagen button {
  /* background-color: #31f080 !important; */
  color: black !important;
  border-color: black !important;
  padding-inline: 5px;
}

.pagen button:disabled {
  background-color: white !important;
}

.pagen span {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  /* font-weight: bold; */
  color: #fff;
  text-align: center;
  /* text-decoration: underline; */
  padding-inline: 5px;
}

.pagen span input {
  border-radius: 5px;
  padding-inline: 10px;
}

.pagen span select {
  font-family: inherit;
}
.pagination {
  padding: 10px;
  display: block;
  justify-content: center;
}
.pagination button {
  margin: 10px;
  /* display: inline-block; */
  /* width: 150px; */
  padding: 10px;
  border-radius: 10px;
  /* border: solid 1px blanchedalmond; */
  transition: all 0.3s;
  color: #000;
  text-align: center;
}
#prev {
  background-color: #d6d6d6;
}
#next {
  background-color: #d6d6d6;
}
.pagination span {
  align-items: normal;
  color: #fff;
  margin-right: 20px;
  font-size: 20px !important;
}
.btns {
  justify-content: center;
  display: flex;
  /* font-size: 20px !important; */
}
.submitpink {
  margin-inline: 5px;
  margin-block: 2vh;
  background: -webkit-linear-gradient(230deg,#fc5c7d,#6a82fb);
  color: #212529 !important;
}
.submitpink:hover {
  filter: drop-shadow(0px 2px 5px #6a82fb);
  /* border: 1px transparent !important; */
}

.submitred {
  margin-inline: 5px;
  margin-block: 2vh;
  background: -webkit-linear-gradient(230deg,#fe3434,#9e270f);
  color: #212529 !important;
}
.submitred:hover {
  filter: drop-shadow(0px 2px 6px #9e430f) !important;
  /* border: 1px transparent !important; */
}
.pageinationbtn {
  justify-content: center;
  display: flex;
  font-size: 20px;
  background-color: #31f080 !important;
  color: black;
  border-color: transparent !important;
  border: 1px transparent !important;
}

.pageinationbtn:hover {
  /* background: -webkit-linear-gradient(0deg, #000 0%, #38ef7d 50%,  #000 100%); */
  box-shadow: 0 0 7px #10ff74;
  border: 1px transparent !important;
  /* text-shadow: 0 0 5px #ffee10; */
}
.pageinationbtn:disabled {
  background-color: transparent !important;
  /* border: 1px solid#31f080 !important; */
  color: rgb(204, 204, 204) !important;
}
.pageinationbtn:active {
  box-shadow: 0 0 10px #10efff !important;
  border: 1px transparent !important;
}
/* BUTTONS! */
#submission button {
  cursor: pointer;
  width: 100%;
  background: linear-gradient(180deg, #7f00ff 0%, #e100ff 100%);
  border-radius: 30px;
  color: #fff;
  margin: 0 0 5px 0;
  padding: 10px;
  font-size: 18px;
  border: none;
}
.btnenabled {
  background: linear-gradient(180deg, #7f00ff 0%, #e100ff 100%);
}
.btnenabled:hover {
  filter: drop-shadow(0px 2px 5px #cf00c8);
}
.btndisabled {
  cursor: pointer;
  width: 100%;
  background: #777777 !important;
}
/* mobile button */
/* .button {
  display: none;
  cursor: pointer;
  width: 300px;
  background: linear-gradient(180deg, #7f00ff 0%, #e100ff 100%);
  border-radius: 30px;
  color: #fff;
  padding: 10px;
  font-size: 15px;
  text-align: center;
  margin: auto;
  margin-top: -32%;
  margin-bottom: 15%;
  border: none;
}
.button .button1 {
  text-align: center;
  text-decoration: none;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #fff;
} */

.autosave {
  display: flex;
  align-content: center;
  align-items: center;
  background: transparent;
  color: #fff!important;
  margin-inline: 5px;
}
.autosavedur {
    padding-inline: 12px;
    margin-inline: 7px;
    width: 60px;
    border-radius: 5px;
}
  
