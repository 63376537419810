@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  margin: 0px;
  padding: 0px;
  font-family: "Poppins", sans-serif;
}

.selectmenubg:hover{
  box-shadow: 0 0 15px aqua !important;
}
#root {
  margin: 0px;
  padding: 0px;
}

.transition-fade-enter {
  opacity: 0;
}

.transition-fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.transition-fade-exit {
  opacity: 1;
}

.transition-fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.svg-icon svg {
  position: relative;
  height: 1.5em;
  width: 1.5em;
}

.svg-text svg {
  stroke: #424242;
}

.svg-180 svg {
  transform: rotate(180deg);
}

.form-input {
  padding: 0.375rem;
  background-color: #eeeeee;
  border: none;
  border-radius: 4px;
  font-size: 0.875rem;
  color: #424242;
}

.form-input:focus {
  outline: none;
  box-shadow: 0 0 1px 2px #8ecae6;
}

/* .is-fullwidth {
  width: 75%;
} */
.borderblack{
  border-bottom: 2px solid #070707;
  border-right: 2px solid #070707;
}
.tdincomplete{
  background: linear-gradient(90deg,#11998e,#38ef7d);
  border-bottom: 2px solid #070707;
  border-right: 2px solid #070707;
  color: #fff !important;

  /* color: #aa0000; */
}
.tdincomplete * {
  color: rgb(0, 0, 0) !important;
}

.td-dark .srno {
  color: #ffffff !important;
}


.bg-white {
  background-color: rgb(255, 255, 255);
}

.data-input {
  white-space: pre-wrap;
  font-family: "Poppins", sans-serif;
  border: none;
  padding: 0.5rem;
  color: #424242;
  font-size: 1rem;
  border-radius: 4px;
  resize: none;
  /* background-color: white; */
  box-sizing: border-box;
  flex: 1 1 auto;
}

.data-input-dark {
  white-space: pre-wrap;
  font-family: "Poppins", sans-serif;
  border: none;
  padding: 0.5rem;
  color: rgb(212, 212, 212);
  font-size: 1rem;
  border-radius: 4px;
  resize: none;
  /* background-color: white; */
  box-sizing: border-box;
  flex: 1 1 auto;
}

.data-input:focus {
  outline: none;
}

.shadow-5 {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.12),
    0 4px 6px rgba(0, 0, 0, 0.12), 0 8px 16px rgba(0, 0, 0, 0.12),
    0 16px 32px rgba(0, 0, 0, 0.12);
}

.svg-icon-sm svg {
  position: relative;
  height: 1.25em;
  width: 1.25em;
}

.svg-gray svg {
  stroke: #9e9e9e;
}

.option-input {
  width: 100%;
  font-size: 1rem;
  border: none;
  background-color: transparent;
}

.option-input:focus {
  outline: none;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 2;
  overflow: hidden;
}

.sort-button {
  padding: 0.25rem 0.75rem;
  width: 100%;
  background-color: transparent;
  border: 0;
  font-size: 0.875rem;
  color: #757575;
  cursor: pointer;
  text-align: left;
  display: flex;
  align-items: center;
}

.sort-button:hover {
  background-color: #fff;
  cursor: default !important;
}

.tr:last-child .td {
  border-bottom: 0;
}

.add-row {
  color: #9e9e9e;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  cursor: pointer;
  height: 50px;
  position: relative;
}

.add-row:hover {
  background: -webkit-linear-gradient(0deg, #002020 0%, #31f080 100%);
  transition: ease;
}


.th {
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  /* cursor: pointer; */
}

/* .th:hover {
  background-color: #01c452;
} */

.th-content {
  overflow-x: hidden;
  background: #121212;
  font-family: "Poppins", sans-serif;
  text-overflow: ellipsis;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  height: 50px;
  color: #f5f5f5;
  border-top: 1px solid #fff;
  border-right: 2px solid #8a8989;
}
/* .th-content:hover {
  background: #31f080;
  overflow-x: hidden;
  text-overflow: ellipsis;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  height: 50px;
  color: #000;
  border-top: 1px solid #fff;
  border-radius: 3px;

} */

.td {
  overflow: overlay;
  color: #424242;
  align-items: stretch;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.td-dark {
  overflow: overlay;
  color: #ffffff;
  align-items: stretch;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.td-content {
  display: block;
}

.table {
  display: inline-block;
  border-spacing: 0;
}

.th:last-child {
  border-right: 0;
}

.td:last-child {
  border-right: 0;
}

.resizer {
  display: inline-block;
  background: transparent;
  width: 8px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  cursor: col-resize;
  touch-action: none;
}

.resizer:hover {
  background-color: #8ecae6;
}

.th {
  white-space: nowrap;
  margin: 0;
  background-color: rgb(255, 255, 255);
  border-bottom: 2px solid #070707;
  /* border-right: 2px solid #070707; */
  position: relative;
}

.td {
  white-space: nowrap;
  margin: 0;
  background-color: rgb(255, 255, 255);
  border-bottom: 2px solid #070707;
  border-right: 2px solid #070707;
  position: relative;
}

.td-dark {
  white-space: nowrap;
  margin: 0;
  background: #0d0c0f;
  border-bottom: 2px solid #8a8989;
  border-right: 2px solid #8a8989;
  position: relative;
}

div.td-dark *.data-input {
  color: #dddddd;
}

div.td-dark > *.ReactTags .ReactTags__tagInput input.ReactTags__tagInputField, .ReactTags .ReactTags__tagInput input.ReactTags__tagInputField:focus{
  background-color: #0d0c0f;
}

div.td2 *.data-input ~ *.select {
  background: #ccc !important ;
}
div.td2[role="cell"]> * {
  background: #ccc !important ;
}

div.td2 > *.react-tag-input {
  background: #ccc !important;
  border-radius: 0 !important;

}

.text-align-right {
  text-align: right;
}

.cell-padding {
  padding: 0.5rem;
}

.d-flex {
  display: flex;
}

.d-inline-block {
  display: inline-block;
}

.cursor-default {
  cursor: default;
}

.align-items-center {
  align-items: center;
}

.flex-wrap-wrap {
  flex-wrap: wrap;
}

.border-radius-md {
  border-radius: 5px;
}

.cursor-pointer {
  cursor: pointer;
}

.icon-margin {
  margin-right: 4px;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-400 {
  font-weight: 400;
}

.font-size-75 {
  font-size: 0.75rem;
}

.flex-1 {
  flex: 1;
}

.mt-5 {
  margin-top: 0.5rem;
}

.mr-auto {
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-5 {
  margin-right: 0.5rem;
}

.justify-content-center {
  justify-content: center;
}

.flex-column {
  flex-direction: column;
}

.overflow-auto {
  overflow: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
  /* margin-top: 5%; */
}
.overflow-y-auto {
  overflow-y: auto;
  margin-top: 1%;
  margin-left: 0vw;
  margin-block: 3vh;
  width: auto;
  height: auto;
  max-height: 90vh;
  border: 3px solid #31f080;
  border-radius: 10px;
}
.profilepg {
  overflow-y: auto;
  margin-top: 1%;
  margin-left: 0vw;
  margin-block: 3vh;
  /* width: auto; */
  height: auto;
  max-height: 90vh;
  border: 3px solid #31f080;
  border-radius: 10px;
}

.list-padding {
  padding: 4px 0px;
}

.bg-grey-200 {
  background-color: #eeeeee;
}

.color-grey-800 {
  color: #424242;
}

.color-grey-600 {
  color: #757575;
}

.color-grey-500 {
  color: #9e9e9e;
}

.border-radius-sm {
  border-radius: 4px;
}

.text-transform-uppercase {
  text-transform: uppercase;
}

.text-transform-capitalize {
  text-transform: capitalize;
}

/* column buttons */
.hid {
  /* position: sticky; */
  top: 5px;
  z-index: 22;
}
.hid label {
  margin-block: 20px;
  /* margin-inline-start: 4px; */
  display: flex;
  /* width: 150px; */
  gap: 5px;
  padding: 10px;
  border-radius: 5px;
  /* border: 1px solid #31f080; */
  transition: all 0.3s;
  color: rgb(0, 0, 0);
  align-items: center;
  /* text-align: center; */
  /* background-color: #31f080; */
}

.hid label:hover {
  box-shadow: 0 0 10px #31f080 !important;
}

/* .hid label input[type="checkbox"] {
  display: none;
} */

/* .hid label input[type="checkbox"]:checked  {
  background-color: aqua;
  align-items: center;
  color: #000000;
} */

/* .hid label input[type="checkbox"] { */
/* border: solid 1px #31f080; */
/* background-color: black !important; */
/* background-color: #31f080 !important;

} */

[data-sticky-td] {
  position: sticky;
}

/* [data-sticky-last-left-td] {
  box-shadow: 2px 0px 3px #ccc;
}

[data-sticky-first-right-td] {
  box-shadow: -2px 0px 3px #ccc;
} */
.hdr {
  color: #000000 !important;
  font-weight: 500;
  font-size: 0.875rem;
  /* cursor: pointer; */
}

.hdr:hover {
  background-color: #01c452;
}

.hdr-content {
  overflow-x: hidden;
  text-overflow: ellipsis;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  height: 50px;
  color: #f5f5f5;
}


audio::-webkit-media-controls-enclosure {
  background-color: #ffffff;
}
.td-dark audio::-webkit-media-controls-enclosure {
  background-color: #0d0c0f;
  background: #0d0c0f;
  color: #31f080;
  border-radius: 5px;
  border:hidden;
}
.td-dark audio::-webkit-media-controls-play-button{
  background-color: #31f080;
  border-radius: 5px;
}
.td-dark audio::-webkit-media-controls-play-button:hover{
  filter: drop-shadow(0px 2px 5px #00cf4f);
}

.td-dark audio::-webkit-media-controls-current-time-display,
.td-dark audio::-webkit-media-controls-time-remaining-display
{
  color: #eeeeee
}

audio::-webkit-media-controls-panel {
  display: flex;
}

.hidecol {
  /* font-size: 16px; */
  background-color: #31f080 !important;
  height: 50px;
  padding-block: 5px;
  align-items: "center";
  font-family: "Poppins", sans-serif;
  border-radius: 5px;
  border-color: "transparent";
  margin-block: 10px;
  /* margin-inline: 2px;  */
  border: transparent;
}

.hidecol:hover {
  box-shadow: 0 0 10px #31f080 !important;
}
.hidecol:enabled {
  background-color: #555 !important;
}
