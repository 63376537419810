.cti__root {
  font-family: sans-serif;
  font-weight: normal;
  font-size: 14px;
  position: relative;
  width: 330px;
}

.cti__root * {
  box-sizing: border-box;
  outline: none;
}

.cti__input {
  border: 1px solid rgb(220, 220, 220);
  border-radius: 5px;
  padding: 0.1em 24px 0.1em 0.1em;
  min-height: 38px;
  position: relative;
  /* background: white; */
  margin: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* overflow-x: scroll; */
  flex-wrap: wrap;
}

.cti__input__arrow {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid rgb(140, 140, 140);
  margin-inline: -4px;
  right: 10px;
}

.cti__input__tags,
.cti__input__input {
  display: inline-block;
  vertical-align: middle;
}

.cti__input__input {
  max-width: 100%;
  padding: 0.15em 0 !important;
  margin: 0.1em 0 0.1em 0.4em !important;
  font-size: 1em !important;
  border: none;
  border-radius: 5px;
  width: fit-content;
}

.cti__input__input::placeholder {
  font-size: 0.51em !important;
}

/* .cti__tag {
  background-color: #31f080;
  border-radius: 5px;
  padding: .1em .3em;
  color: black;
  display: inline-block;
  margin: .1em  0 .1em .4em;
  cursor: pointer;
  word-break: break-all;
  max-width: 100%;
} */

.cti__tag {
  background: -webkit-linear-gradient(130deg,#63ddd3,#31f080);
  border-radius: 5px;
  padding: 0.1em 0.3em;
  color: var(--toastify-color-dark);
  display: -webkit-box;
  /* margin: .em  0.2em .1em .4em; */
  cursor: pointer;
  word-break: break-word;
  max-width: 100%;
  margin-inline: 5px;
  margin-block: 6px;
}

.cti__tag:hover {
  /* box-shadow: 0 0 7px #10ffcb; */

  filter: drop-shadow(0px 2px 5px #00cf4f);
  /* border: 1px transparent !important; */

  /* background-color: #31f080; */
  /* color: var(--toastify-color-dark); */
}

.cti__tag__content--match {
  color: black;
  font-weight: bold;
}

.cti__tag__delete,
.cti__tag__content,
.cti__tag__content > span {
  display: inline-block;
  vertical-align: middle;
}

.cti__tag__delete {
  background-color: transparent;
  padding: 0 0 0 0.3em;
  margin-block: -10px;
  color: rgb(146, 146, 146);
  font-size: 1.5em;
  border: none;
}

.cti__tag__delete:hover {
  color: #ff4c10;
}

.cti__tag__content > span {
  margin-top: -3px;
}

.cti__panel {
  position: absolute;
  background: white;
  /* border: 1px solid rgb(220, 220, 220); */
  border-top: none;
  width: 100%;
  z-index: 9997;
}

.cti__category__add-item {
  background: transparent;
  border: none;
  color: rgb(28, 99, 166);
  font-size: 1em;
}

.cti__category__add-item.cti-selected {
  margin-left: 0.4em;
}

.cti__category__or {
  margin-left: 0.4em;
}

.cti-selected {
  /* background-color: rgb(229, 241, 255); */
  /* border: 1px solid rgb(207, 219, 234); */
  box-shadow: 0 0 10px #107cff;
  border-radius: 5px;
}

.cti__category {
  padding: 0.4em;
  display: block;
  column-count: 3;
  -moz-column-count: 3;
  -webkit-column-count: 3;
}

.cti__category__title {
  padding: 0.3em 0.56em;
  margin: 0;
  /* color: rgb(200, 200, 200); */
  font-weight: bold;
  width: fit-content;
}

.cti__category__tags {
  width: 120px;
  display: block;
}

