.ReactTags .app {
	padding: 40px;
	text-align: center;
	overflow-x: scroll;
	
	/* Example Styles for React Tags*/
  }
  .ReactTags .container {
	margin: auto;
	display: flex;
	justify-content: center !important;
	align-items: center;
	overflow-x: scroll;
	
  }
  .ReactTags .ReactTags__tags {
	position: relative;
	display: flex;
    align-content: center;
    justify-content: left;
	height: 60px;
	align-items: center;
	margin-left: 10px;
	/* margin-top: 30px; */
  }
  .ReactTags .ReactTags__clearAll {
	cursor: pointer;
	padding: 10px;
	margin: 10px;
	background: #f88d8d;
	color: #fff;
	border: none;
	/* Styles for the input */
  }
  .ReactTags .ReactTags__tagInput {
	/* border-radius: 2px; */
	display: inline-block;
  }
  .ReactTags .ReactTags__tagInput input.ReactTags__tagInputField, .ReactTags .ReactTags__tagInput input.ReactTags__tagInputField:focus {
	
		/* height: 100%; */
		margin: 0 5px;
		font-size: 12px;
		padding: 3px;
		border: 1px solid #fff; 	 	
		border-radius: 5px;
		width: 100px;
		text-overflow: ellipsis;
		/* background: #; */
		color: #fff;
  }
  .ReactTags .ReactTags__editInput {
	border: 1px solid #eee;
	border-radius: 5px;
  }
  .ReactTags .ReactTags__editTagInput {
	display: inline-flex;


	/* Styles for selected tags */
  }
  .ReactTags .ReactTags__selected span.ReactTags__tag {
	/* border: 1px solid #ddd;
	background: #63bcfd;
	color: white;
	font-size: 12px;
	display: inline-block;
	border-radius: 2px; */
	margin: 0 3px;
	padding: 5px;
	background: -webkit-linear-gradient(130deg,#63ddd3,#00ff69);
	font-size: 16px !important; /* specific font size */
	color: black;
	border-color: transparent !important;
	border: 1px transparent !important;
	border-radius: 5px !important;
	margin-block: 5px !important;
  }
  .ReactTags .ReactTags__selected span.ReactTags__tag:first-child {
	margin-left: 0px;
  }
  .ReactTags .ReactTags__selected a.ReactTags__remove {
	color: rgb(0, 0, 0);
	margin-left: 5px;
	cursor: pointer;
	/* Styles for suggestions */
  }
  .ReactTags .ReactTags__suggestions {
	position: absolute;
	overflow: visible !important; 
	/* color: rgb(255, 255, 255); */
}
  .ReactTags .ReactTags__suggestions ul {
	list-style-type: none;
	z-index: 8;
	box-shadow: 0 0 8px #0af392;	
	/* background: -webkit-linear-gradient(135deg,#6afbe8,#ffffff); */
	border-radius: 5px;
	/* height: 30px; */
	/* width: 200px; */
	padding: 0;
	white-space: inherit !important;
  }
  .ReactTags .ReactTags__suggestions li {
	border-bottom: 1px solid rgb(0, 0, 0);
	padding: 5px 10px;
	margin: 0;
	text-align: center;
  }
  .ReactTags .ReactTags__suggestions li mark {
	text-decoration: underline;
	background: none;
	font-weight: 600;
	color: rgb(0, 0, 0);
  }
  .ReactTags .ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: -webkit-linear-gradient(135deg,#06f5d5,#06f37c);
	border-radius: 5px;
	/* border-bottom-right-radius: 5px; */
	/* background-clip: text; */
	/* color: #000; */
    /* -webkit-background-clip: text; */
    /* -webkit-text-fill-color: transparent; */
	cursor: pointer;
  }
  .ReactTags .ReactTags__remove {
	border: none;
	cursor: pointer;
	background: none;
	color: rgb(0, 0, 0);
  }
  
  .ReactTags .ReactTags__tag:hover {
	box-shadow: 0 0 7px #10ffcb;
  }
