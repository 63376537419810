/* REACT TAG INPUT */
.react-tag-input {
    font-size: 20px !important; /* specific font size */
    font-size: 1.3rem !important; /* responsive font size */
    font-family: "Poppins", sans-serif;
    width: 50vw !important;
    display: flex;
    justify-content: center;
  }
  .react-tag-input__tag {
    background-color: #31f080 !important;
    color: black;
    border-color: transparent !important;
    border: 1px transparent !important;
    border-radius: 5px !important;
    margin-block: 5px !important;
  }
  .react-tag-input__tag:hover {
    box-shadow: 0 0 7px #10ff74;
  }
  .react-tag-input {
    background: rgb(0, 0, 0) !important;
    color: #333 !important;
    border: 1px solid #e1e1e1 !important;
    box-shadow: 0 0 7px #10ff74;
    border: 1px transparent !important;
    border-radius: 10px !important;
  }
  .react-tag-input__tag__remove {
    background: transparent !important;
  }
  input.react-tag-input__input::placeholder {
    color: aliceblue;
  }
  .react-tag-input__input {
    background: transparent;
    color: white !important;
  }
  